import * as React from 'react'
import { HeadFC, PageProps } from 'gatsby'

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <div>
      <h1>Error 404</h1>
    </div>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>404 | Digit Universe</title>